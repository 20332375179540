import React, { useEffect, useRef, useState } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput = ({ setSelectedLocation, initialQuery = "" }) => {
  const [query, setQuery] = useState(initialQuery); // Set initial query from props
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "USA" }, // Optional: Restrict to specific country
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();

    if (!addressObject || !addressObject.address_components) {
      return; // Handle cases where no address is selected
    }

    const formattedAddress = addressObject.formatted_address;
    const addressComponents = addressObject.address_components;

    // Extract relevant address components
    const getAddressComponent = (types) => {
      const component = addressComponents.find((comp) =>
        comp.types.some((type) => types.includes(type))
      );
      return component ? component.long_name : '';
    };

    const city = getAddressComponent(['locality', 'administrative_area_level_3']);
    const state = getAddressComponent(['administrative_area_level_1']);
    const country = getAddressComponent(['country']);
    const postalCode = getAddressComponent(['postal_code']);

    // Update query and pass back extracted fields
    updateQuery(formattedAddress);
    setSelectedLocation(formattedAddress, city, state, country, postalCode);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    setQuery(initialQuery); // Update query when initialQuery changes
  }, [initialQuery]);

  return (
    <>
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search Street..."
        value={query}
        autoComplete="off"
        required
        style={{ width: "100%", boxSizing: "border-box", padding: "8px", fontSize: "16px" }}  
      />
    </>
  );
};

export default SearchLocationInput;