import logo from '../../Assets/logo.png'

const Navbar = () => {
    return (
        <>
            <div className="nav-bg">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <img className="navbar-brand logo" src={logo} alt=""></img>
                        <h2 className="main-heading">Quick Care</h2>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link" style={{paddingRight: '20px'}} aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link contact-link" aria-current="page" href="/login">Login</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar