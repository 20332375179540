import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { handleAddLab, handleUpdateLab, fetchLabs } from '../../ApiHandler/ClinicFunctions';

const AddLab = () => {
    const [labData, setLabData] = useState({
        name: '',
        labId: '',
        phoneNo: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        addressLine2: '',
        instructions: '',
    });
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false); 

    useEffect(() => {
        fetchLabs(setLabData, setIsUpdate); 
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLabData({
            ...labData,
            [name]: value,
        });
    };

    const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
        setLabData((prevData) => ({
            ...prevData, // Preserve all existing fields
            street: formattedAddress,
            city: city || prevData.city,
            state: state || prevData.state,
            country: country || prevData.country,
            postalCode: postalCode || prevData.postalCode
        }));
    };

    const handleSubmit = (e) => {
        if (isUpdate) {
            handleUpdateLab(e, labData, setLoading);
        } else {
            handleAddLab(e, labData, setLoading);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="add-patient-form">
                <h3>{isUpdate ? "Update Lab Data" : "Add Lab Data"}</h3>
                <div className="form-group">
                    <label>Lab Name</label>
                    <input
                        type="text"
                        name="name"
                        value={labData.name}
                        onChange={handleChange}
                        placeholder="Enter Lab Name"
                    />
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Lab Identification Number</label>
                        <input
                            type="text"
                            name="labId"
                            value={labData.labId}
                            onChange={handleChange}
                            placeholder="Enter Lab ID"
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input
                            type="text"
                            name="phoneNo"
                            value={labData.phoneNo}
                            onChange={handleChange}
                            placeholder="Enter Phone Number"
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Street</label>
                        <SearchLocationInput
                            setSelectedLocation={handleSelectedLocation}
                            initialQuery={labData.street}
                        />
                    </div>
                    <div className="form-group">
                        <label>City</label>
                        <input
                            type="text"
                            name="city"
                            value={labData.city}
                            onChange={handleChange}
                            placeholder="Enter City"
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            type="text"
                            name="postalCode"
                            value={labData.postalCode}
                            onChange={handleChange}
                            placeholder="Enter Postal Code"
                        />
                    </div>
                    <div className="form-group">
                        <label>State</label>
                        <input
                            type="text"
                            name="state"
                            value={labData.state}
                            onChange={handleChange}
                            placeholder="Enter State"
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={labData.country}
                            onChange={handleChange}
                            placeholder="Enter Country"
                        />
                    </div>
                    <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                            type="text"
                            name="addressLine2"
                            placeholder="Enter your address (Optional)"
                            value={labData.addressLine2}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Sample Drop Off Instructions</label>
                    <textarea
                        type="text"
                        name="instructions"
                        value={labData.instructions}
                        onChange={handleChange}
                        placeholder="Enter Sample Drop Off Instructions"
                    />
                </div>
                <button
                    type="submit"
                    className="profile-save-button"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {isUpdate ? (loading ? 'Updating...' : 'Update') : (loading ? 'Saving...' : 'Save')}
                </button>

            </div >
        </>
    );
};

export default AddLab;