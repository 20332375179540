import Navbar from "./Navbar";
import LandingPage from "./LandingPage";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Navbar/>
      <LandingPage/>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;