import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPateintsDetails, updatePatientsAuthorization } from '../../ApiHandler/PatientFunctions';
import { checkPaymentDetails } from '../../ApiHandler/PaymentFunctions';
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';
import { fetchLabs } from '../../ApiHandler/ClinicFunctions';
import { handleOrderKits } from '../../ApiHandler/AlertFunctions';
import ConfirmationModal from '../Utils/ConfirmationModal';

const Authenticate = () => {
    const [patients, setPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [hasPaymentDetails, setHasPaymentDetails] = useState(false);
    const [hasLabDetails, setHasLabDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [kits, setKits] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(null);

    // Check if clinic has saved payment details on component load
    useEffect(() => {
        checkPaymentDetails(setHasPaymentDetails);
    }, []);

    useEffect(() => {
        fetchPateintsDetails(setPatients);
    }, []);

    useEffect(() => {
        fetchKits(setKits);
    }, []);

    useEffect(() => {
        fetchLabs((labData) => {
            const requiredFields = [
                'name',
                'labId',
                'phoneNo',
                'street',
                'city',
                'postalCode',
                'state',
                'country',
                'instructions',
            ];

            // Check if all required fields are filled
            const isComplete = requiredFields.every((field) => labData[field] && labData[field].trim() !== '');
            setHasLabDetails(isComplete);
        });
    }, []);

    const getKitNameById = (kitId) => {
        const kit = kits.find((k) => k.id === Number(kitId));
        return kit ? `kit ${kits.indexOf(kit) + 1}` : 'Unknown Kit';
    };

    // Handle search input change
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter patients based on search term
    const filteredPatients = patients.filter((patient) =>
        patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Toggle checkbox for all patients
    const handleSelectAll = () => {
        const newStatus = !selectAll;
        setSelectAll(newStatus);
        setPatients((prevPatients) =>
            prevPatients.map((patient) => ({ ...patient, isChecked: newStatus }))
        );
    };

    // Handle individual checkbox
    const handleCheckboxChange = (patientId) => {
        setPatients((prevPatients) =>
            prevPatients.map((patient) =>
                patient.patient_id === patientId
                    ? { ...patient, isChecked: !patient.isChecked }
                    : patient
            )
        );
    };

    const handlePlaceOrder = () => {
        setModalAction(() => () => handleOrderKits(patients, setLoading));
        setIsModalOpen(true);
    };

    const handleConfirmAction = () => {
        setIsModalOpen(false);
        if (modalAction) modalAction();
    };

    // Authenticate selected patients
    const handleAuthenticate = async () => {
        const selectedPatients = patients.filter((patient) => patient.isChecked);
        const selectedIds = selectedPatients.map((patient) => patient.patient_id);

        try {
            await updatePatientsAuthorization(selectedIds, true, setPatients, setLoading); // true for authenticate
            // After successful authentication, set authorized status to 1
            setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                    selectedIds.includes(patient.patient_id)
                        ? { ...patient, authorized: 1 }
                        : patient
                )
            );
        } catch (error) {
            console.error('Error updating patients:', error);
        }
    };

    return (
        <>
            <ToastContainer />
            <ConfirmationModal
                isOpen={isModalOpen}
                title="Confirm Action"
                message="Are you sure you want to place the order?"
                onConfirm={handleConfirmAction}
                onCancel={() => setIsModalOpen(false)}
            />
            <div className="patients-container">
                <div className="add-patient-container">
                    <div className='in-row-input'>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search Patients"
                                value={searchTerm}
                                onChange={handleSearch}
                                className="search-input"
                            />
                        </div>
                        {(!hasPaymentDetails || !hasLabDetails) && (
                            <div className="warning-top" style={{ color: '#FFA500', textAlign: 'center', marginTop: '5px' }}>
                                Please add your card details and lab details before placing orders
                            </div>
                        )}
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Patient Name</th>
                                    <th>Patient Email</th>
                                    <th>Patient Phone</th>
                                    <th>Patient Address</th>
                                    <th>Shipping Type</th>
                                    <th>Frequency</th>
                                    <th>Kit Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPatients.map((patient) => (
                                    <tr key={patient.patient_id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={patient.isChecked || false}
                                                onChange={() => handleCheckboxChange(patient.patient_id)}
                                            />
                                        </td>
                                        <td>{patient.patient_name}</td>
                                        <td>{patient.patient_email}</td>
                                        <td>{patient.patient_phone}</td>
                                        <td>{patient.patient_street}</td>
                                        <td>{patient.shipping_type}</td>
                                        <td>{patient.frequency}</td>
                                        <td>{getKitNameById(patient.kit_type)}</td>
                                        <td>
                                            <span
                                                className="status-dot"
                                                style={{
                                                    backgroundColor: patient.authorized ? 'green' : 'red',
                                                    height: '10px',
                                                    width: '10px',
                                                    borderRadius: '50%',
                                                    display: 'inline-block',
                                                    marginRight: '10px',
                                                }}
                                            ></span>
                                            {patient.authorized === 1 ? (
                                                <span className="not-authorized">Authorized</span>
                                            ) : (
                                                <span className="not-authorized">Not Authorized</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="in-row-input">
                        <button className="save-button" onClick={handleAuthenticate} disabled={loading}>
                            {loading ? 'Authenticating...' : 'Authenticate'}
                        </button>
                        <button
                            style={{ backgroundColor: 'green' }}
                            className="save-button"
                            onClick={handlePlaceOrder}
                            disabled={!hasPaymentDetails || !hasLabDetails || loading}
                        >
                            {loading ? 'Placing Order...' : 'Place Order Kits'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Authenticate;