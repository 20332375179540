import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentCancel = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [cancel, setCancel] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');

        if (!sessionId) {
            setError('Missing session ID');
            setLoading(false);
            return;
        }

        // Simulate backend validation or logging cancelation
        const validateCancellation = async (sessionId) => {
            try {
                // Example API call to log the canceled session or validate it
                // await logCancellation(sessionId);
                setCancel(true);
            } catch (err) {
                setError('Failed to validate cancellation');
            } finally {
                setLoading(false);
            }
        };

        validateCancellation(sessionId);
    }, [location.search]);

    if (loading) return <div style={{ color: 'black', marginTop: '20px' }}><h2>Loading...</h2></div>;
    if (error) return <div style={{ color: 'red', marginTop: '20px' }}><h2>{error}</h2></div>;
    if (cancel) return (
        <div style={{ color: 'orange', marginTop: '20px' }}>
            <h2>Payment Cancelled</h2>
            <p>It seems you canceled the payment process. If this was a mistake, you can try again.</p>
            <button onClick={() => navigate('/dashboard')} className='save-button'>Go to back to the dashboard and try again</button>
        </div>
    );

    return <div style={{ color: 'black', marginTop: '20px' }}><h2>Failed to process cancellation!</h2></div>;
};

export default PaymentCancel;