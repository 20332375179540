import { useState } from "react";
import Careers from "./Careers";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import navIcon1 from '../../Assets/nav-icon1.svg';
import navIcon2 from '../../Assets/nav-icon2.svg';
import navIcon3 from '../../Assets/nav-icon3.svg';

function Footer() {
  const date = new Date();
  const [showModal, setShowModal] = useState(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="align-items-center">
            <Col size={12} sm={4}>
              <img src={logo} alt="Logo" />
            </Col>
            <Col size={12} sm={4}>
            </Col>
            <Col size={12} sm={4} className="text-center text-sm-end">
              <a className="careers-page" aria-current="page" href="# " onClick={handleShow}>Careers</a>
              <div className="social-icon">
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <img src={navIcon1} alt="" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noreferrer">
                  <img src={navIcon2} alt="" />
                </a>
                <a href="https://tiktok.com" target="_blank" rel="noreferrer">
                  <img src={navIcon3} alt="" />
                </a>
              </div>
              <p>
                ⓒ {date.getFullYear()} Product Advisory, All Rights Reserved. <br />
                {/* contactus@productadvisory.org <br /> */}
                981 Mission Street, San Francisco, CA, 94103, US
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <Careers show={showModal} handleClose={handleClose} />
    </>
  );
}

export default Footer;