import React, { useEffect, useState } from 'react';
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';
import DOMPurify from 'dompurify';

const KitsInfo = () => {
    const [activeKit, setActiveKit] = useState(0);
    const [kits, setKits] = useState([]);

    useEffect(() => {
        fetchKits(setKits);
    }, []);

    const renderKitInfo = () => {
        if (kits.length === 0) return <p>Loading...</p>;

        const kit = kits[activeKit];

        return (
            <div className="kit-section">
                <h2>{kit.name}</h2>
                <h3>Price: ${kit.price}</h3>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(kit.description) }} />
            </div>
        );
    };

    return (
        <div className="patients-container">
            <div className="form-container">
                <h1>Kit Information</h1>
                <div className="kit-buttons">
                    {kits.map((kit, index) => (
                        <button key={kit.id} onClick={() => setActiveKit(index)}>
                            {kit.name}
                        </button>
                    ))}
                </div>
                <div className='kits-container'>
                    {renderKitInfo()}
                </div>
            </div>
        </div>
    );
};

export default KitsInfo;