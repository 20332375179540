import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

// Function to confirm patient address and order kit
export const handleConfirmAddressOrderKit = async (token, setConfirmed, setError, setLoading) => {
    try {
        setLoading(true);

        // Send POST request to the backend
        const response = await Axios.post(`${API_URL}/order/confirm-address-order-kit`, { token });

        if (response.data.success) {
            setConfirmed(true);
            toast.success('Address confirmed successfully! Your order is being processed.', { position: 'top-center' });
        } else {
            // Backend returned an error message but no exception
            const backendErrorMessage = response.data.message || 'An unknown error occurred while confirming the address.';
            setError(backendErrorMessage);
            toast.error(backendErrorMessage, { position: 'top-center' });
        }
    } catch (error) {
        let errorMessage = 'Failed to confirm address. Please try again later.';

        if (error.response) {
            // Backend returned an error response
            errorMessage = error.response.data?.message || 'An error occurred on the server.';
            if (error.response.status === 400) {
                errorMessage = 'Invalid or expired token. Please request a new confirmation link.';
            } else if (error.response.status === 500) {
                errorMessage = 'Server encountered an issue. Please try again later.';
            }
        } else if (error.request) {
            // Request was made but no response received
            errorMessage = 'Unable to connect to the server. Please check your internet connection.';
        } else {
            // General error during request setup
            errorMessage = error.message || 'An error occurred. Please try again.';
        }

        setError(errorMessage);
        toast.error(errorMessage, { position: 'top-center' });
    } finally {
        setLoading(false);
    }
};