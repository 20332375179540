import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./Navbar";
import Footer from "../Home/Footer";
import VerifyEmail from "./VerifyEmail.jsx"
import TermsModal from './TermsModel.jsx';
import { validatePassword } from "../Utils/PasswordValidator.js";
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { handlePatientSignUp, sendOtp, verifyOtp } from '../../ApiHandler/AuthFunctions.js';  // Assuming you have this API handler
import { fetchClinics, handleClinicRequest } from '../../ApiHandler/ClinicFunctions.js';

const PatientSignUp = () => {
    const [formDetails, setFormDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        insuranceOption: 'yes',
        clinicId: '',
    });

    const [passwordErrors, setPasswordErrors] = useState([]);
    const [clinics, setClinics] = useState([]);  // State to store fetched clinics
    const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false); // For opening and closing popup
    const [clinicRequestDetails, setClinicRequestDetails] = useState({
        clinicName: '',
        clinicEmail: '',
        clinicAddress: '',
        clinicPhone: '',
    });
    const [requestSubmitted, setRequestSubmitted] = useState(false);  // To track submission
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [otp, setOtp] = useState(''); //  Code entered by the user
    const [otpStage, setOtpStage] = useState(false);
    const [showClinics, setShowClinics] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    // Fetch clinics when the component mounts
    useEffect(() => {
        fetchClinics(setClinics);
    }, []);

    // Update form details when fields are changed
    const handleChange = (e) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = (password) => {
        setFormDetails({ ...formDetails, password });
        const errors = validatePassword(password);
        setPasswordErrors(errors);
    };

    // Handle changes for the clinic request form
    const handleClinicRequestChange = (e) => {
        setClinicRequestDetails({ ...clinicRequestDetails, [e.target.name]: e.target.value });
    };

    const handleCheckBoxChange = (e) => {
        setIsRequestPopupOpen(e.target.checked);
        setShowClinics(prevState => !prevState);
    }

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const verifyResponse = await verifyOtp(formDetails.email, otp);
        if (verifyResponse.success) {
            // Proceed with registration after  Code verification
            await handlePatientSignUp(e, formDetails, setFormDetails);
            setOtp('');
            setOtpStage(false);
        } else {
            toast.error(verifyResponse.message, {
                position: "top-center"
            });
        }
        setLoading(false);
    };

    const handleSelectedLocation = (formattedAddress) => {
        setClinicRequestDetails({ ...clinicRequestDetails, clinicAddress: formattedAddress });
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <div className="auth-container">
                {!otpStage ? (
                    <>
                        <h1>Register as Patient</h1>
                        <p>Please enter your details</p>
                        <form onSubmit={(e) => sendOtp(e, passwordErrors, formDetails.email, formDetails.phone, formDetails.password, formDetails.confirmPassword, setOtpLoading, setOtpStage)}>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formDetails.firstName}
                                        onChange={handleChange}
                                        placeholder="Enter your first name"
                                        required />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formDetails.lastName}
                                        onChange={handleChange}
                                        placeholder="Enter your last name"
                                        required />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={formDetails.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="phone"
                                        placeholder="Enter your phone number"
                                        name="phone"
                                        value={formDetails.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        placeholder="Enter your password"
                                        name="password"
                                        value={formDetails.password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        required
                                    />
                                    {passwordErrors.length > 0 && (
                                        <ul className="password-errors">
                                            {passwordErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        placeholder="Enter your password again"
                                        name="confirmPassword"
                                        value={formDetails.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            {!showClinics && (
                                <div className="form-group">
                                    <label>Select Clinic</label>
                                    <select
                                        name="clinicId"
                                        value={formDetails.clinicId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a Clinic</option>
                                        {clinics.map((clinic) => (
                                            <option key={clinic.clinic_id} value={clinic.clinic_id}>
                                                {clinic.clinic_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="checkbox-group">
                                <span>Don't have your clinic in the list? Request Clinic Onboarding</span>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleCheckBoxChange(e)}
                                />
                            </div>
                            <div className="checkbox-group">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox"
                                    checked={termsAccepted}
                                    onChange={() => setShowTermsModal(true)}
                                    required
                                />
                                <span htmlFor="termsCheckbox">I agree to the Terms and Conditions</span>
                            </div>
                            <button type="submit" className="sign-in-button" disabled={otpLoading}>
                                {otpLoading ? 'Sending Code...' : 'Sign Up'}
                            </button>
                            {/* <p>Already have an account? <a href="/login">Log In</a></p> */}
                        </form>
                        <TermsModal show={showTermsModal} accept={() => { setShowTermsModal(false); setTermsAccepted(true); }} onClose={() => { setShowTermsModal(false)}} />
                    </>
                ) : (
                    <VerifyEmail
                        otp={otp}
                        setOtp={setOtp}
                        loading={loading}
                        handleOtpSubmit={handleOtpSubmit}
                    />
                )}
            </div>
            {isRequestPopupOpen && (
                <div className="clinic-request-popup">
                    <div className="popup-content">
                        {!requestSubmitted ? (
                            <>
                                <div className="success-message-header">
                                    <h2>Request Clinic Onboarding</h2>
                                    <button onClick={() => setIsRequestPopupOpen(false)} className="close-btn">&times;</button>
                                </div>
                                <form onSubmit={(e) => handleClinicRequest(e, clinicRequestDetails, setRequestSubmitted, setClinicRequestDetails, setLoading)}>
                                    <div className="form-group">
                                        <label>Clinic Name</label>
                                        <input
                                            type="text"
                                            name="clinicName"
                                            value={clinicRequestDetails.clinicName}
                                            onChange={handleClinicRequestChange}
                                            placeholder="Enter clinic name"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Clinic Email</label>
                                        <input
                                            type="email"
                                            name="clinicEmail"
                                            value={clinicRequestDetails.clinicEmail}
                                            onChange={handleClinicRequestChange}
                                            placeholder="Enter clinic email"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Clinic Address</label>
                                        <SearchLocationInput setSelectedLocation={handleSelectedLocation} />
                                    </div>
                                    <div className="form-group">
                                        <label>Clinic Phone</label>
                                        <input
                                            type="phone"
                                            name="clinicPhone"
                                            value={clinicRequestDetails.clinicPhone}
                                            onChange={handleClinicRequestChange}
                                            placeholder="Enter clinic phone"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="submit-request-button" disabled={loading}>
                                        {loading ? 'Submitting...' : 'Submit Request'}
                                    </button>
                                </form>
                            </>
                        ) : (
                            <div className="request-success-message">
                                <div className="success-message-header">
                                    <h3>Your request has been sent!</h3>
                                    <button onClick={() => { setIsRequestPopupOpen(false); setRequestSubmitted(false) }} className="close-btn">&times;</button>
                                </div>
                                <p>You can sign up now</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};

export default PatientSignUp;
