const Careers = ({ show, handleClose }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Careers at Product Advisory</h2>
                    <span className="close-btn" onClick={handleClose}>&times;</span>
                </div>
                <div className="modal-body">
                    <p>Thank you for your interest in joining our team! We're always looking for talented and passionate individuals to help us revolutionize health testing. If you believe you have what it takes to make a difference, we'd love to hear from you.</p>
                    <p>To apply or inquire about open positions, please fill out our <a href="https://forms.gle/Z1xEtckdpZE2wR167" target="_blank" rel="noopener noreferrer">hiring form</a></p>
                </div>
            </div>
        </div>
    );
};

export default Careers;