import React from "react";

const VerifyEmail = ({otp, setOtp, loading, handleOtpSubmit}) => {
    return (
        <React.Fragment>
            <h2>We need to verify your email.</h2>
            <form onSubmit={handleOtpSubmit}>
                <div className="form-group">
                    <label>We have emailed you a Verification Code. Please check your email and enter the verification code here</label>
                    <input
                        type="text"
                        name="otp"
                        placeholder="Enter Code"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="sign-in-button" disabled={loading}>
                    {loading ? 'Verifying  Code...' : 'Submit Code'}
                </button>
            </form>
        </React.Fragment>
    );
};

export default VerifyEmail;