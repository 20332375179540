import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

// Function to fetch onboarded clinics
export const fetchClinics = async (setClinics) => {
    try {
        const response = await Axios.get(`${API_URL}/clinic/fetch-clinics`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            setClinics(response.data);
        } else {
            console.error('Failed to fetch clinics');
        }
    } catch (error) {
        console.error('Error fetching clinics:', error);
    }
};


// Function to fetch clinic profile data
export const fetchClinicProfile = async (setFormData) => {
    try {
        const response = await Axios.get(`${API_URL}/clinic/fetch-clinic-profile`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            setFormData({
                email: response.data.clinic_email,
                phoneNumber: response.data.phone_number,
                clinicName: response.data.clinic_name,
                address: response.data.clinic_address,
                patients: response.data.patient_count,
                street: response.data.clinic_street,
                city: response.data.clinic_city,
                postalCode: response.data.clinic_postal_code,
                state: response.data.clinic_state,
                country: response.data.clinic_country,
                addressLine2: response.data.address_line_2
            });
        } else {
            console.error('Error fetching clinic data');
        }
    } catch (error) {
        console.error('Error fetching clinic data:', error);
    }
};


// Function to update clinic profile data
export const updateClinicProfile = async (e, formData, setLoading) => {
    e.preventDefault();
    if (String(formData.phoneNumber).trim() === '' ||
        String(formData.clinicName).trim() === '' ||
        String(formData.street).trim() === '' ||
        String(formData.city).trim() === '' ||
        String(formData.postalCode).trim() === '' ||
        String(formData.state).trim() === '' ||
        String(formData.country).trim() === '' ||
        String(formData.patients).trim() === '') {
        toast.error('Please enter all the required fields', {
            position: 'top-center',
        });
        return;
    }
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/clinic/update-clinic-profile`, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            toast.success('Profile updated successfully', {
                position: 'top-center',
            });
        } else {
            toast.error('Error updating profile', {
                position: 'top-center',
            });
        }
    } catch (error) {
        console.error('Error updating clinic profile:', error);
        toast.error('Server error', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};


// Function to add lab
export const handleAddLab = async (e, formData, setLoading) => {
    e.preventDefault();
    if (String(formData.name).trim() === '' ||
        String(formData.labId).trim() === '' ||
        String(formData.phoneNo).trim() === '' ||
        String(formData.street).trim() === '' ||
        String(formData.city).trim() === '' ||
        String(formData.postalCode).trim() === '' ||
        String(formData.state).trim() === '' ||
        String(formData.country).trim() === '' ||
        String(formData.instructions).trim() === '') {
        toast.error('Please enter all the required fields', {
            position: 'top-center',
        });
        return;
    }

    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/clinic/add-lab`, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            toast.success('Lab added successfully', {
                position: 'top-center',
            });
        } else {
            toast.error('Error adding lab, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        console.error('Error adding lab:', error);
        toast.error('Server error, please try again later!', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};


// Function to fetch lab data added by a clinic
export const fetchLabs = async (setLabData, setIsUpdate) => {
    try {
        const response = await Axios.get(`${API_URL}/clinic/fetch-labs`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200 && response.data) {
            // If lab data is found, set the form data and mark as update mode
            setLabData({
                name: response.data.lab_name,
                labId: response.data.lab_id_no,
                phoneNo: response.data.lab_phone,
                street: response.data.lab_street,
                city: response.data.lab_city,
                postalCode: response.data.lab_postal_code,
                state: response.data.lab_state,
                country: response.data.lab_country,
                addressLine2: response.data.address_line_2,
                instructions: response.data.instructions,
            });
            setIsUpdate(true); // Switch to update mode
        } else {
            console.error('Error fetching labs');
        }

    } catch (error) {
        console.error('Error fetching labs:', error);
    }
};


// Function to update lab details
export const handleUpdateLab = async (e, formData, setLoading) => {
    e.preventDefault();
    if (String(formData.name).trim() === '' ||
        String(formData.labId).trim() === '' ||
        String(formData.phoneNo).trim() === '' ||
        String(formData.street).trim() === '' ||
        String(formData.city).trim() === '' ||
        String(formData.postalCode).trim() === '' ||
        String(formData.state).trim() === '' ||
        String(formData.country).trim() === '' ||
        String(formData.instructions).trim() === '') {
        toast.error('Please enter all the required fields', {
            position: 'top-center',
        });
        return;
    }

    try {
        setLoading(true);
        const response = await Axios.put(`${API_URL}/clinic/update-lab`, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            toast.success('Lab updated successfully', {
                position: 'top-center',
            });
        } else {
            toast.error('Error updating lab, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        console.error('Error updating lab:', error);
        toast.error('Server error, please try again later!', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};


// Function to send a request to onboard a clinic
export const handleClinicRequest = async (e, clinicRequestDetails, setRequestSubmitted, setClinicRequestDetails, setLoading) => {
    e.preventDefault();
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/clinic/request-clinic`, clinicRequestDetails, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            setRequestSubmitted(true);
            setClinicRequestDetails({
                clinicName: '',
                clinicEmail: '',
                clinicAddress: '',
                clinicPhone: '',
            });
        } else {
            toast.error('Error sending clinic request, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        console.error('Error sending clinic request:', error);
        toast.error('Server error', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};