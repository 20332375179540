import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;


// Function to place orders for authenticated patients
export const handleOrderKits = async (patients, setLoading) => {
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/alert/request-address-confirmation`, { patients }, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200 && response.data.success) {
            toast.success('Address verification email sent to patients. Kits will ship after verification', {
                position: 'top-center',
            });
        } else {
            console.error('Failed to order kits:', response.data.message);
        }
    } catch (error) {
        console.error('Error ordering kits:', error);
        toast.error('An error occurred while ordering kits, please try again later!', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};