import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import howdoesitwork from '../../Assets/how_does_it_work.png';
// B2C Images 
import imgb2c1 from '../../Assets/b2c/b2c1.png';
import imgb2c2 from '../../Assets/b2c/b2c2.png';
import imgb2c3 from '../../Assets/b2c/b2c3.png';
import imgb2c4 from '../../Assets/b2c/b2c4.png';
import imgb2c5 from '../../Assets/b2c/b2c5.png';
import imgb2c6 from '../../Assets/b2c/b2c6.png';
import imgb2c7 from '../../Assets/b2c/b2c7.png';
// B2B Images 
import imgb2b1 from '../../Assets/b2b/b2b1.png';
import imgb2b2 from '../../Assets/b2b/b2b2.png';
import imgb2b3 from '../../Assets/b2b/b2b3.png';
import imgb2b4 from '../../Assets/b2b/b2b4.png';
import imgb2b5 from '../../Assets/b2b/b2b5.png';
import imgb2b6 from '../../Assets/b2b/b2b6.png';
import imgb2b7 from '../../Assets/b2b/b2b7.png';
import imgb2b8 from '../../Assets/b2b/b2b8.png';
import imgb2b9 from '../../Assets/b2b/b2b9.png';
import imgb2b10 from '../../Assets/b2b/b2b10.png';
import imgb2b11 from '../../Assets/b2b/b2b11.png';

function Main() {
    const [index, setIndex] = useState(0);
    const [showB2CCarousel, setShowB2CCarousel] = useState(true);
    const handleSelect = (selectedIndex) => setIndex(selectedIndex);

    const b2cImages = [imgb2c1, imgb2c2, imgb2c3, imgb2c4, imgb2c5, imgb2c6, imgb2c7];
    const b2bImages = [imgb2b1, imgb2b2, imgb2b3, imgb2b4, imgb2b5, imgb2b6, imgb2b7, imgb2b8, imgb2b9, imgb2b10, imgb2b11];
    const images = showB2CCarousel ? b2cImages : b2bImages;


    const handleB2CSwitch = (e) => {
        setShowB2CCarousel(true);
    };

    const handleB2BSwitch = (e) => {
        setShowB2CCarousel(false);
    };

    return (
        <>
            <div className="main-page-heading">
                <h1>Home Testing Kits for Time-Saving Solutions</h1>
                <p>Streamlining health care by reducing in-person visits with fast, accurate, and confidential home testing kits.</p>
            </div>
            <div>
                <h3>How does it work</h3>
                <img src={howdoesitwork} className='how-does-it-work-image' alt="how-does-it-work" />
            </div>
            <div className="switch-carousel-container">
                <button onClick={handleB2BSwitch} className={!showB2CCarousel ? 'active' : ''}>
                    Clinic
                </button>
                <button onClick={handleB2CSwitch} className={showB2CCarousel ? 'active' : ''}>
                    Patient
                </button>
            </div>
            <div id='who-we-are'>
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    controls={true}
                    indicators={false}
                >
                    {images.map((image, i) => (
                        <Carousel.Item key={i}>
                            <img className="d-block w-100" src={image} alt={`Slide ${i + 1}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <div className="carousel-pagination">
                    {images.map((_, i) => (
                        <button
                            key={i}
                            className={`pagination-button ${index === i ? 'active' : ''}`}
                            onClick={() => setIndex(i)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>

            <div className="landing-page-cards">
                <div className="page-card">
                    <h3>Vision</h3>
                    <p>Provide accessible testing kits to people with convenient, at-home testing solutions.</p>
                </div>
                <div className="page-card">
                    <h3>Mission</h3>
                    <p>Reduce the burden on healthcare facilities by minimizing the need for routine in-person testing.</p>
                </div>
            </div>
        </>
    );
}

export default Main;