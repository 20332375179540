import React, { useState, useEffect } from 'react';
import AddLab from './AddLab';
import EditProfile from './EditProfile';

const ClinicProfile = () => {
    const [activeSidebarOption, setActiveSidebarOption] = useState(localStorage.getItem('activeSidebarOption') || 'profile');

    useEffect(() => {
        localStorage.setItem('activeSidebarOption', activeSidebarOption);
    }, [activeSidebarOption]);

    return (
        <div className="patients-container">
            <div className="form-container">
                <h2>Clinic Profile</h2>
                <div className="kit-selection-buttons">
                    <button
                        type="button"
                        className={`customized-kit-button ${activeSidebarOption === 'profile' ? 'active-button' : ''}`}
                        onClick={() => setActiveSidebarOption('profile')}
                    >
                        Profile
                    </button>
                    <button
                        type="button"
                        className={`standard-kit-button ${activeSidebarOption === 'lab' ? 'active-button' : ''}`}
                        onClick={() => setActiveSidebarOption('lab')}
                    >
                        Sample Testing Lab
                    </button>
                </div>
                {activeSidebarOption === 'profile' && (
                    <EditProfile />
                )}

                {activeSidebarOption === 'lab' && (
                    <AddLab />
                )}
            </div>
        </div>
    );
};

export default ClinicProfile;