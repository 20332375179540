import Axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;


export const fetchKits = async (setKits) => {
    try {
        const response = await Axios.get(`${API_URL}/shopify/fetch-products`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200) {
            setKits(response.data);
        } else {
            console.error('Error fetching kits');
        }

    } catch (error) {
        console.error('Error fetching kits:', error);
    }
};