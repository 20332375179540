import * as XLSX from 'xlsx';

export const importPatientsFromExcel = (file, setPatients, kits) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Converting the sheet data to JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: ['name', 'email', 'phone', 'age', 'street', 'city', 'postalCode', 'state', 'country', 'shippingType', 'frequency', 'kitType'],
      defval: '',  
    }).slice(1);

    // Create a mapping of "Kit 1", "Kit 2", etc. to kit ids
    const kitNameToIdMap = kits.reduce((acc, kit, index) => {
      // Create a mapping with "Kit 1", "Kit 2", etc., and the corresponding kit id
      acc[`kit ${index + 1}`] = kit.id;
      return acc;
    }, {});

    // We can now map the jsonData to match the patients array structure
    const importedPatients = jsonData.map((row) => {
      // Get the kit id from the mapping, default to null if not found
      const kitId = kitNameToIdMap[row.kitType.toLowerCase()] || null;

      return {
        name: row.name,
        email: row.email,
        phone: row.phone,
        age: row.age,
        street: row.street,
        city: row.city,
        postalCode: row.postalCode,
        state: row.state,
        country: row.country,
        shippingType: row.shippingType,
        frequency: row.frequency,
        kitType: kitId,  // Set the kit id
        isChecked: false,
      };
    });

    // Update the patients state with the imported data
    setPatients(importedPatients);
  };

  reader.readAsArrayBuffer(file);
};
