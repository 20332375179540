import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { handleConfirmAddressOrderKit } from '../../ApiHandler/OrderFunctions';
import ConfirmationModal from '../Utils/ConfirmationModal';

const AddressConfirmation = () => {
    const { token } = useParams();
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    // const [address, setAddress] = useState(null);  // State to hold patient address details

    // useEffect(() => {
    //     fetchPatientAddress(setAddress, setError);
    // }, [token]);

    const handleConfirmAddress = () => {
        setModalAction(() => () => handleConfirmAddressOrderKit(token, setConfirmed, setError, setLoading));
        setIsModalOpen(true);
    };

    const handleConfirmAction = () => {
        setIsModalOpen(false);
        if (modalAction) modalAction();
    };

    return (
        <div className="auth-container">
            <ToastContainer />
            <ConfirmationModal
                isOpen={isModalOpen}
                title="Confirm Action"
                message="Are you sure you?"
                onConfirm={handleConfirmAction}
                onCancel={() => setIsModalOpen(false)}
            />
            <h1>Confirm Your Address</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {/* {address && !confirmed && (
                <div>
                    <h2>Address Details</h2>
                    <p><strong>Street:</strong> {address.street}</p>
                    <p><strong>City:</strong> {address.city}</p>
                    <p><strong>State:</strong> {address.state}</p>
                    <p><strong>Country:</strong> {address.country}</p>
                    <p><strong>Postal Code:</strong> {address.postalCode}</p>
                </div>
            )} */}

            {confirmed ? (
                <div style={{ marginTop: '20px' }}>
                    <h3>Address Confirmed</h3>
                    <p>Thank you! Your address has been successfully confirmed.</p>
                    <p>You may now close this page.</p>
                </div>
            ) : (
                <button
                    className="save-button"
                    onClick={handleConfirmAddress}
                    disabled={loading}
                >
                    {loading ? 'Confirming Address...' : 'Confirm Address'}
                </button>
            )}
        </div>
    );
};

export default AddressConfirmation;